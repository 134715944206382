import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { TiTick, TiTimes} from "react-icons/ti";
import ImageGallery from '../../components/PromoImageGallery/ImageGallery';

function ShuaibPostop() {

    const generalRef = useRef();
    const donorRef = useRef();
    const transplantedRef = useRef();
    const washingRef = useRef();
    const medicationRef = useRef();
    const complicationRef = useRef();


    const handleGeneral = () => generalRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleDonor = () => donorRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleTransplanted = () => transplantedRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleWashing = () => washingRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleMedication = () => medicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    const handleComplication = () => complicationRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    
    return (
        <div className="row px-3 mt-5">
            <div className="col-md-3">
                <div className='d-sm-block d-md-none border mb-5 pb-5'>
                    <ImageGallery />
                </div>
                <div className="border d-none d-md-block" style={{'position':'sticky', 'top':100}}>
                    <ul className='border-bottom py-3' style={{'listStyle':'none'}}>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleGeneral}>
                            The Do's And Don'ts
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleDonor}>
                                Care Of Donor Area
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleTransplanted}>
                                Care Of Transplanted Area
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleWashing}>
                                Hair Washing Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleMedication}>
                                Medication Regimen
                            </span>
                        </li>
                        <li className="py-1">
                            <span className="sidebarNav" onClick={handleComplication}>
                                Complications That May Occur After Surgery
                            </span>
                        </li>
                    </ul>
                    <div className='mt-3'>
                        <a href='https://21again.co.uk/'>
                            <ImageGallery />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div id="post-instructions">
                    <header>
                        <h3 className="text-center">Post Operatives Instructions</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card" ref={ generalRef }>
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Shuaib/postop.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <h5>The Do's</h5>
                                            <ul style={{'listStyle': 'none', 'paddingLeft': 0}}>
                                                <li className="pb-2"><TiTick color="green" />DO Use the spray bottle (saline spray) you are supplied with on discharge. Spray the transplanted area every 20 mins on the day of surgery, every 30 mins for the first 3 days and then every hour for the first 14 days. It is not necessary to spray during the night but if you wish to do so you can spray 2-3 times at night for the first 3 days. Once the sterile solution provided is finished you may use bottled mineral water to fill it up.</li>
                                                <li className="pb-2"><TiTick color="green" />DO Sleep in an upright position or your side or back at least for the first week.</li>
                                                <li className="pb-2"><TiTick color="green" />DO Ensure you take your post-operative medications as per instructions. You are given steroid medication for the next few days, to avoid swelling of the eyes and forehead, it is imperative that you take it.</li>
                                                <li className="pb-2"><TiTick color="green" />DO Wash the transplanted area from day 3 as per instruction.</li>
                                                <li className="pb-2"><TiTick color="green" />DO Use Antihistamine (Piriton OR Cetirizine) to reduce repetitive itching.</li>
                                                <li className="pb-2"><TiTick color="green" />DO Remove the bandage from the donor area after 24hours however the headband stays on for 5 days (day and night). Place icepacks over the headband for 10 minutes every 30 mins for 2 days. These measures are to minimise the swelling from travelling down to the eyes/face.</li>
                                                <li className="pb-2"><TiTick color="green" />DO Eat light meals, ideally soft meals with minimal chewing for 2-3 post surgery, avoid salty foods and do not add extra salt to your food over the next 5 days following surgery.</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-12 col-md-6">
                                            <h5>The Don'ts</h5>
                                            <ul style={{'listStyle': 'none', 'paddingLeft': 0}}>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Scratch the head at any time as it is important that germs are not transferred from your fingers/hands to the donor or transplant area as this could cause infection. Do not touch the recipient and donor areas until the day you start washing them which will be on the 3rd day.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Wear hats for the first 14 days after surgery. IF you are hair piece wearer DO NOT put it on again until at least 14 days after surgery. </li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Use hair spray or a hairdryer during the first 14 days.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Do any strenuous exercise or use the gym for 14 days following the surgery.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Lean your head forwards, bend over, or turn your head upside down for the first 2 days.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Expose your head to direct sunlight or sunbeds for at least 14 days following surgery. Avoid sunburn on the head for 2 months after the operation.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Use a swimming pool, sauna, or steam rooms for 2 months. No swimming in the sea for 3 weeks.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Take aspirin or any medication containing Salicylic Acid the day following your operation. Also avoid the use of any non-prescribed substances for 7 days following the procedure.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Use clippers or shave the hair for 2 months, but scissors may be used gently to cut the hair after 14 days.</li>
                                                <li className="pb-2"><TiTimes color="red" />DO NOT Smoke or drink for 2 months post-surgery as it will delay your healing. Exception for drinks: 1 small cup of wine/spirit (or shot) a week post-surgery is permitted, however excessive alcohol and smoking should be avoided for first 2 months.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <img src="https://patient-portal.s3.fr-par.scw.cloud/website/images/postopTimeline.png" alt="Timeline" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="washing">
                    <header>
                        <h3 className="text-center">Taking Care After Surgery</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Shuaib/washing.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body">
                                    <h5 ref={ donorRef }><u>Care Of Donor Area</u></h5>
                                    <p>
                                        The following evening after surgery (24 hours at least), remove your headband by gently soaking the bandage at the back of your head with warm water in order to ensure it does not stick to skin as you gently peel it away. If at any time there is bleeding, <b>do not be concerned</b>. Apply direct pressure with a dry, clean cloth until the bleeding stops. This may take several minutes If you have any concerns or questions, please contact us. Once this is completed reapply your headband.
                                    </p>

                                    <h5 className="mt-3" ref={transplantedRef}><u>Care Of Transplanted Area</u></h5>
                                    <p>
                                        During the surgery, your scalp is thoroughly cleaned of blood and scabs, but these will tend to re-form soon after your procedure. Care will need to be taken while cleaning the transplanted area during the first 10 days, the healing process of your body secure the grafts firmly in place. If the grafts are soaked too long, they may swell and rise above the skin surface and appear as little white bumps immediately after showering and for the first few days after the operation. It is not harmful to the grafts, but indicates that you are soaking too much. As soon as you allow your scalp to dry, they will disappear. If the scalp becomes too dry, you should switch to moisturizing conditioner on the 6th day and continue showering using the Baby Shampoo only and conditioner moving forward. We recommend using a natural/organic shampoo, the less ingredients the better. <b>It is essential that follow the instructions to achieve the best results.</b>
                                        <br/>
                                        Use coconut oil if you feel your scalp is dry and aloe vera gel if the scalp is itchy or sore 14 days post-surgery. 
                                        <br/>
                                        If you have been previously using either minoxidil and/or finasteride, you can restart this after 2 weeks.
                                        <br/>Please note that it is advised to continue these treatments for at least 1 year.
                                    </p>

                                    <h5 className="mt-3" ref={ washingRef }><u>Hair Washing Regimen</u></h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Instructions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>3</td>
                                                    <td>
                                                        Wash the scalp by using a solution of half baby shampoo and half water diluted into a little spray bottle, spraying it all over your head and tapping very gently over the transplanted area. Leave for 1-3 minutes and gently rinse off by pouring a cup of water. 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td><b>No washing</b></td>
                                                </tr>
                                                <tr>
                                                    <td>5-13</td>
                                                    <td>
                                                        Wash the scalp by using a solution of half baby shampoo and half water diluted into a cup and pouring it onto your head. Gently tapping over the transplanted area and using your fingertips over the donor area, rinse off by pouring a cup of water.
                                                        <br/>
                                                        <b>NO shower jet stream over the transplanted area.</b>
                                                        <br/>
                                                        <b>To avoid any harm </b>to the transplanted area, we advise you to let the scalp air dry, but you can dry the transplanted area with a paper towel. Gentle dabbing is advised and avoid rubbing. Towels can be used in other areas of the scalp.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td>
                                                        Apply the shampoo on the transplanted area using circular massage movements applying more pressure and keep it on the scalp for 1 hour. Rinse off. Dry scabs should fall off between day 14 and 16.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Onwards</td>
                                                    <td>
                                                        At this point you can go back to your usual shampoo if you wish. For follow up purposes we provide aftercare consultation review after 6 and 12 months. Shower normally after 2 weeks.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                    <b>
                                        <span>IMPORTANT: </span>
                                        No chemical-based products such as hair colour, hair lacquer or sprays etc should be used on the head for 4 weeks following the operation. Shampooing or rinsing the transplanted area must be gentle for the first 10 days. So, DO NOT RUB, PICK, or SCRATCH as this may dislodge grafts.    
                                    </b>
                                    <br/>
                                    <p className="mt-3">
                                        <span className="fw-bold">Do not use tar shampoo</span> (a dark-colored, medicated shampoo used for psoriasis) on the transplanted area for 10 days following your operation, as this may interfere with the growth of the grafts.
                                    </p>
                                    <p className="mt-3 fw-bold">
                                        Please note that when hairs are shed, there may be a crust at the top and a bulb at the bottom. Remember the bulb is not the root (the growth part of the hair follicle). It is normal for the hair, bulb, and associated crust to be shed and this does not represent a lost graft. If a graft is lost (something that may occur the first few days following the operation) there will be bleeding at the site of the lost graft. Therefore, if you do not see any bleeding, do not be concerned.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="medicine">
                    <header>
                        <h3 className="text-center">MEDICATION REGIMEN</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <ReactPlayer url='https://patient-portal.s3.fr-par.scw.cloud/website/videos/Shuaib/medication.mp4' className="card-img-bottom" width='100%' height='450px' controls={true}/>
                                <div className="card-body" ref={ medicationRef }>
                                    <p>
                                        Medication plays an important role in aiding your recovery, managing the pain, and also preventing or limiting the side effects. We provide a range of medication to ensure just this.
                                        <br/>
                                        The medication we provide are:
                                    </p>
                                    <ul>
                                        <li>Doxycycline - an antibiotic. Helps prevent any infection post procedure.</li>
                                        <li>Prednisolone - steroid and anti-inflammatory agent. Mainly to reduce the inflammation & helps to reduce swelling that may occur to the forehead and around the eyes post op. It is important that once started <b>you do not suddenly stop</b> it and must be weaned down slowly. Any concerns, please contact your GP or seek medical advice.</li>
                                        <li>Lansoprazole - antacid agent used as a stomach protector. This is taken on an empty stomach half an hour before breakfast, once daily.</li>
                                    </ul>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Evening</th>
                                                    <th>Morning</th>
                                                    <th>Morning</th>
                                                </tr>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>After Dinner</th>
                                                    <th>30 minutes Before Breakfast</th>
                                                    <th>After Breakfast</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Day of Operation</td>
                                                    <td>2 pills of Doxycycline</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>1 pill of Doxycycline</td>
                                                    <td>1 pill of Lansoprazole</td>
                                                    <td>3 pills of Prednisolone</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>1 pill of Doxycycline</td>
                                                    <td>1 pill of Lansoprazole</td>
                                                    <td>2 pills of Prednisolone</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>1 pill of Doxycycline</td>
                                                    <td>1 pill of Lansoprazole</td>
                                                    <td>2 pills of Prednisolone</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>1 pill of Doxycycline</td>
                                                    <td>1 pill of Lansoprazole</td>
                                                    <td>1 pill of Prednisolone</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>1 pill of Doxycycline</td>
                                                    <td>1 pill of Lansoprazole</td>
                                                    <td>1 pill of Prednisolone</td>
                                                </tr>
                                                <tr>
                                                    <td>Package Leaflet</td>
                                                    <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Doxycycline.pdf" target="blank">Doxycycline Leaflet</a></td>
                                                    <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Lansoprazole.pdf" target="blank">Lansoprazole Leaflet</a></td>
                                                    <td><a href="https://patient-portal.s3.fr-par.scw.cloud/website/medicationInstructions/Prednisolone.pdf" target="blank">Prednisolone Leaflet</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p>We advise you to follow the medication regime above. Please take care in ensuring you take the appropriate medication at the correct timings. If you have any questions, please do not hesitate to contact us, or speak with your GP or seek medical advice/ A&E.</p>
                                    <br/>
                                    <p><span className="fw-bold"><u>Additionally,</u></span> you can use the following medication on an <span className="fw-bold"><u>as required</u></span> basis: </p>
                                    <ul>
                                        <li><b>If you experience itching to the scalp and it becomes intolerable,</b> an antihistamine can be used. In such case you can take Piriton 4mg three times a day or Cetirizine 10mg once daily (which can cause drowsiness). Both are readily available over the counter medications.</li>
                                        <li><b>If you experience pain,</b> you can take paracetamol 500mg, two tablets every six hours for two days then at the patient’s discretion, if the pain persists then please seek medical advice [GP or A&E].</li>
                                    </ul>
                                    <b>*PLEASE CONSULT YOUR GP BEFORE TAKING ANY MEDICATION.*</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="complication" ref={complicationRef}>
                    <header>
                        <h3 className="text-center">COMPLICATIONS THAT MAY OCCUR AFTER SURGERY</h3>
                    </header>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <ul>
                                        <li><b>Swelling</b> is quite rare but could occur usually 24 to 48 hours after the surgery. You may notice some swelling in the forehead, which may travel down towards the eyebrows or eyes. This will resolve itself, without intervention, usually 48 to 72 hours after it begins. Sleeping raised will help.</li>
                                        <li><b>Bleeding</b>: there may be some bleeding directly after the surgery from the donor area, if any bleeding occurs, it is important to apply pressure with some gauze for as long as it takes to settle.</li>
                                        <li><b>Pain</b>: sometimes pain can occur in the donor, the transplanted area or your neck area due to the procedure/ the position, then Paracetomol is recommended for this.</li>
                                        <li><b>Numbness / tingling sensation</b> in the recipient area sometimes occurs, this will resolve without intervention. It can last from few weeks to few months. Infection: There is a very low risk of infection as with any surgery. You should return to the clinic if you feel there is any signs of infection such as localised redness, pain…etc.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShuaibPostop
